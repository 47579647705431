<template>
  <div style="background-color: #f9f9f9">
    <!-- top -->
    <div style="height: 209px; width: 100%;background-color: #fff;" >
      <div class="maxtitle flex-space-between">
        <div class="listtab flex">
          <div class="leftbox" v-for="(item, index) in listtab" :key="index">
            <div :class="actindex==index?'title actvietitle':'title' " @click="tabclick(item.id,index)">{{ item.name }}</div>
            <!-- <div class="Subtitle">{{ series.en_name }}</div> -->
          </div>
        </div>
        <div class="rightbox flex-space-between">
          <input
            type="text"
            v-model="inpnav"
            placeholder="请输入关键词查询"
            placeholder-style="color:#777; font-size: 16px;"
          />
          <img src="@/assets/page/xfdj.png" alt="" />
        </div>
      </div>
      <div class="goback flex-space-between">
        <div class="leftbox flex">
          <div class="imgbox"><img src="@/assets/page/fangz.png" alt="" /></div>
          <div class="title">板材</div>
          <div style="margin: 0 4px">-</div>
          <div class="series">{{ series.name }}</div>
          <!-- <div style="margin: 0 4px">-</div> -->
          <!-- <div class="name">莱茵河经典纯色.深空灰</div> -->
        </div>
        <div class="rightbox flex" @click="gobackclick">
          <div class="fhbox">返回</div>
          <div class="imgbox"><img src="@/assets/page/fh.png" alt="" /></div>
        </div>
      </div>
    
    </div>
    <!-- list -->
    <div class="listbox" v-if="listarr.length != 0">
      <div
        class="itembox"
        v-for="(item, index) in listarr"
        :key="index"
        @click="itemclick(item.id)"
        @mouseover="mouover(index)"
        @mouseleave="monve(index)"
      >
        <div class="imgbox">
          <img :src="item.images" alt="" />
        </div>
        <div class="rightbox">
          <div class="titletext">{{ item.title }}</div>
          <div class="Subtitletext">{{ item.en_title }}</div>
          <!-- <div class="time">{{ item.date }}</div> -->
          <div class="imgboxs" v-show="activeindex != index"><img src="@/assets/page/heijt.png" alt="" /></div>
          <div class="imgboxs" v-show="activeindex == index"><img src="@/assets/page/lj.png" alt="" /></div>
        </div>
      </div>
    </div>

    <!-- 缺失页 -->
    <div class="maxqsbox" v-else>
      <div class="qsbox">
        <img src="@/assets/page/qst.png" alt="" />
        <div>暂无数据</div>
      </div>
    </div>

    <div class="fenye">
      <el-pagination
        background
        class="fenye"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        layout="prev, pager, next"
        :page-size="page.per_page"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { panelCasedata,getPanelSeries } from '../../service/request/homepage'
export default {
  data() {
    return {
      inpnav: '',
      listtab:[],
      activeindex: null,
      id: null,
      listarr: [],
      actindex:0,
      page: {
        page: 1,
        last_page: 0,
        per_page: 8,
        total: 1
      },
      // 主体
      series: {}
    }
  },
  components: {},
  mounted() {
    this.id = this.$route.query.id
    this.initdata()
    this.getPanelSeries()
  },
  watch: {
    inpnav: {
      handler() {
        this.initdata()
      }
    }
  },
  methods: {
   
    getPanelSeries() {
      getPanelSeries().then(res => {
        console.log(res, '1');
        this.listtab=res.msg
      })
    },
    tabclick(id, index) {
      this.actindex=index
      this.id = id
      this.page.page=1
      this.initdata()
    },
    //初始数据
    initdata() {
      console.log(this.$route.query.id)
      panelCasedata({
        id: this.id,
        key_words: this.inpnav,
        page: this.page.page
      }).then((res) => {
        console.log('23', res)
        this.page.last_page = res.msg.last_page
        this.page.per_page = res.msg.per_page
        this.page.total = res.msg.total
        this.listarr = res.msg.data
        this.series = res.msg.series
        if (res.msg.series.name == '纯色系列') return this.actindex = 1
        if (res.msg.series.name == '石纹系列') return this.actindex = 2
        if(res.msg.series.name=='木纹系列') return this.actindex=3
      })
    },
    // 返回
    gobackclick() {
      this.$router.go(-1)
    },
    //详情页面
    itemclick(id) {
      this.$router.push({
        path: '/boardinfo',
        query: {
          id
        }
      })
    },
    handleCurrentChange(e) {
      this.page.page = e
      this.initdata()
      window.scrollTo(0, 0)
    },
    mouover(index) {
      this.activeindex = index
    },
    monve() {
      this.activeindex = null
    }
  }
}
</script>
<style scoped lang="less">
.goback {
  // position: relative;
  // padding-top: 188px;
  margin: 0 85px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .leftbox {
    .imgbox {
      width: 19px;
      height: 16px;
      line-height: 10px;
      margin-right: 9px;
      img {
        width: 19px;
        height: 16px;
      }
    }
    .title,
    .series {
      font-size: 16px;
      color: #999999;
    }
    .name {
      color: #3e907c;
      font-size: 16px;
    }
  }
  .rightbox {
    .fhbox {
      font-size: 16px;
      color: #3e907c;
      margin-right: 6px;
    }
    .imgbox {
      width: 20px;
      height: 14px;
      line-height: 10px;
      img {
        width: 20px;
        height: 14px;
      }
    }
  }
}
.maxtitle {
  margin: 140px 85px 0;
  background-color: #fff;
  .listtab {
    width: 800px;
  }
  .leftbox {
    // text-align: right;
    margin-right: 57px;
    .title {
      font-size: 24px;
      color: #999999;
      border-bottom:1px solid #fff ;
    }
    .actvietitle{
      color: #000;
      border-bottom:1px solid #000000 ;
    }
    .Subtitle {
      margin-top: 20px;
      font-size: 16px;
      color: #999999;
    }
  }
  .rightbox {
    margin-top: 20px;
    background-color: #fff;
    width: 451px;
    height: 50px;

    border-radius: 20px;
    input {
      border: none;
      width: 228px;
      height: 22px;
      margin-top: 16px;
      padding-left: 15px;
      font-size: 16px;
    }

    input::-webkit-input-placeholder {
      font-size: 16px;
      color: #bbbbbb;
    }
    input:-moz-placeholder {
      font-size: 16px;
      color: #bbbbbb;
    }
    input:-ms-input-placeholder {
      font-size: 16px;
      color: #bbbbbb;
    }
    img {
      margin-top: 13px;
      margin-right: 25px;
      width: 24px;
      height: 24px;
    }
  }
}
.listbox {
  margin: 0 85px;
  min-height: 500px;
  overflow: hidden;
  .itembox {
    cursor: pointer;
    transition: 0.6s all;
    margin-top: 33px;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    .imgbox {
      margin: 33px 0 33px 30px;
      width: 316px;
      height: 222px;
      img {
        width: 316px;
        height: 222px;
      }
    }
    .rightbox {
      margin-left: 36px;
      .titletext {
        margin-top: 51px;
        font-size: 24px;
        color: #000000;
      }
      .Subtitletext {
        margin-top: 50px;
        font-size: 16px;
        color: #666666;
      }
      .time {
        margin-top: 18px;
        color: #999999;
        font-size: 16px;
      }
      .imgboxs {
        margin-top: 24px;
        width: 50px;
        height: 12px;
        img {
          width: 50px;
          height: 12px;
        }
      }
    }
  }
  .itembox:hover {
    transition: 0.6s all;
    transform: translateY(-10px);
  }
  .itembox:hover .titletext {
    color: #3e907c;
  }
  .itembox:hover .Subtitletext {
    color: #3e907c;
  }
  .itembox:hover .time {
    color: #3e907c;
  }

  .itembox:nth-child(1) {
    margin-top: 0;
  }
  .itembox:last-child {
    // p-bottom: 104px;
    margin-bottom: 64px;
  }
}
.fenye {
  margin: 0 85px 0px;
  padding-bottom: 64px;
  display: flex;
  justify-content: flex-end;
}
.maxqsbox {
  width: 100%;
  display: flex;
  justify-content: center;

  .qsbox {
    margin: 0 auto;
    min-height: 500px;

    text-align: center;
    img {
      width: 112px;
      height: 112px;
    }
    div {
      margin-top: 10px;
      color: #999;
    }
  }
}
</style>
